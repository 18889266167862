import dayjs from "dayjs";

export const formatDateTime = (date: string) => {
  return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
};

export const helperEndOfMonth = (date: Date): string => {
  const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59, 999);
  return lastDayOfMonth.toString();
};

export const getDateRangeDefaultChart = () => {
  const currentDate = new Date();
  const startOfLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1, 0, 0, 0);
  const endOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0, 23, 59, 59);
  return [startOfLastMonth, endOfCurrentMonth];
};