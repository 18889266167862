import React, { useMemo, useState } from "react";
import { BarChart, CalendarRange, TrendingUp } from "lucide-react";
import CountUp from "react-countup";
import { SalesRevenueOverviewChart } from "./Charts";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import { useQuery } from "@tanstack/react-query";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";
import { getTotalOrderTotalPriceV1ShopifyShopSystemShopIdSystemAnalyticsOrderTotalOrderTotalPricePostOptions } from "api/client/@tanstack/react-query.gen";
import { format, addMonths, differenceInMonths } from "date-fns";
import { cls } from "utils/theme";
import { helperEndOfMonth, formatDateTime, getDateRangeDefaultChart } from "utils/date-time";

type SalesRevenueProps = {
  system_shop_id?: string;
  className?: string;
  currency?: string;
};

const SalesRevenue = (props: SalesRevenueProps) => {
  const { className, currency } = props;
  let currentDate = new Date();

  const [dateRange, setDateRange] = useState<Date[]>(getDateRangeDefaultChart());
  const { data: monthlyRevenue } = useQuery({
    ...getTotalOrderTotalPriceV1ShopifyShopSystemShopIdSystemAnalyticsOrderTotalOrderTotalPricePostOptions(
      {
        path: { system_shop_id: props.system_shop_id ?? "" },
        body: {
          start_date: formatDateTime(dateRange?.[0]?.toString()),
          end_date: formatDateTime(
            helperEndOfMonth(new Date(dateRange?.[1]?.toString()))
          ),
        },
      }
    ),
    enabled: !!props.system_shop_id,
  });

  const totalSales = useMemo(() => {
    return (
      (monthlyRevenue?.data?.at(0) as any)?.data?.reduce(
        (acc: number, item: number) => acc + item,
        0
      ) ?? 0
    );
  }, [monthlyRevenue]);

  const totalProfits = useMemo(() => {
    return (
      (monthlyRevenue?.data?.at(1) as any)?.data?.reduce(
        (acc: number, item: number) => acc + item,
        0
      ) ?? 0
    );
  }, [monthlyRevenue]);

  const arrayOfMonths = useMemo(() => {
    const startDate = dateRange?.[0]?.toISOString();
    const endDate = dateRange?.[1]?.toISOString();
    if (!startDate || !endDate) return [];

    const start = new Date(startDate);
    const end = new Date(endDate);
    const totalMonths = differenceInMonths(end, start);
    // Generate the list of month short names
    const months = [];
    for (let i = 0; i <= totalMonths; i++) {
      const monthDate = addMonths(start, i);
      months.push(format(monthDate, "MMM.yyyy"));
    }
    return months;
  }, [dateRange]);

  const renamedSeries = useMemo(() => {
    return monthlyRevenue?.data?.map((item: any, index: number) => {
      return {
        ...item,
        data: item?.data?.slice(0, item?.data?.length - 2) || [],
        name: item.name === "Total Sales" ? "Total Sales Revenue" : item.name,
      };
    }) ?? [];
  }, [monthlyRevenue]);
  return (
    <React.Fragment>
      <div className={cls("col-span-12 card 2xl:col-span-8 ", className)}>
        <div className="card-body">
          <div className="flex flex-col gap-4 mb-4 md:mb-3 md:items-center md:flex-row">
            <h6 className="grow text-15">
              Total Sales Revenue and Total Profits
            </h6>
            <div className="relative">
              <CalendarRange className="absolute size-4 ltr:left-3 rtl:right-3 top-3 text-slate-500 dark:text-zink-200"></CalendarRange>
                     {/* @ts-ignore */}
              <Flatpickr
                className="ltr:pl-10 rtl:pr-10 form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                options={{
                  mode: "range", 
                  plugins: [
                    monthSelectPlugin({
                      shorthand: true,
                      dateFormat: "M,Y",
                    }),
                  ],
                }}
                value={dateRange}
                onChange={(date) => {
                  if(date.length === 1){
                    return
                  }
                  setDateRange(date)
                }}
                placeholder="Select Date"
              />
              <style>{`
                 .flatpickr-months .flatpickr-month .flatpickr-current-month {
                      justify-content: center;
                  }
                `}</style>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-4 mb-3">
            <div className="col-span-12 md:col-span-6 lg:col-span-3">
              <div className="flex items-center gap-3">
                <div className="flex items-center justify-center size-12 rounded-md text-sky-500 bg-sky-50 shrink-0 dark:bg-sky-500/10">
                  <BarChart />
                </div>
                <div className="grow">
                  <p className="mb-1 text-slate-500 dark:text-zink-200">
                    Total Sales Revenue
                  </p>
                  <h5 className="text-15">
                    <CountUp
                      end={totalSales}
                      decimals={2}
                      className="counter-value"
                    />{" "}
                    {currency}
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-3">
              <div className="flex items-center gap-3">
                <div className="flex items-center justify-center size-12 text-green-500 rounded-md bg-green-50 shrink-0 dark:bg-green-500/10">
                  <TrendingUp />
                </div>
                <div className="grow">
                  <p className="mb-1 text-slate-500 dark:text-zink-200">
                    Total Profit
                  </p>
                  <h5 className="text-15">
                    <CountUp
                      end={totalProfits}
                      decimals={2}
                      className="counter-value"
                    />{" "}
                    {currency}
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <SalesRevenueOverviewChart
            chartId="salesRevenueOverview"
            series={renamedSeries ?? []}
            categories={arrayOfMonths ?? []}
            currency={currency}
          />
         
        </div>
      </div>
    </React.Fragment>
  );
};

export default SalesRevenue;
